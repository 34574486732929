
.messagebox .outgoing{
    display: flex;
    width: 50%;
    margin-bottom: 1rem;
  }
  .messagebox .incoming{
    display: flex;
    width: 50%;
    justify-content: flex-end;
    margin-left: auto;
    margin-bottom: 1rem;
  }
  
  .messagebox .outgoing p{
    color: white;
    padding: 0.5rem;
    border-radius: 10px;
    margin-bottom: 0;
    width: fit-content;
  }
  
  .messagebox .incoming p{
    padding: 0.5rem;
    border-radius: 10px;
    margin-bottom: 0;
    margin-left: auto;
    width: fit-content;
    text-align: left;
  }
  
  .messagebox .incoming small{
    margin-left: auto;
    width: fit-content;
    text-align: left;
  }
  
.overviewselect{
    z-index: 999999;
    position: absolute;
    top: 15px;
    right: 21px;
  }
  .overviewselect select{
     width: fit-content;
  }
  